var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c(
      "div",
      { staticClass: "container", attrs: { id: "privacy_policy_page" } },
      [
        _c("h1", [_vm._v("Privacy Policy")]),
        _c("p", [
          _vm._v(
            "This webpage sets out when and how we use your personal information that you or others provide to us. Your privacy is of the utmost importance to us. We want to make sure you fully understand the terms and conditions surrounding the capture and use of that information. This privacy policy describes what information we collect about you, how we use it, and the rights you have in relation to that collection and usage."
          )
        ]),
        _c("p", [
          _vm._v(
            "By accessing our Smart Mobility and Carpooling platform (the “Facilities“) through Sharo’s applications for mobile devices and websites (collectively, the “App”), or visiting our sharo.io website you are accepting and consenting to the practices described in this policy."
          )
        ]),
        _c("p", [
          _vm._v(
            "If you have any questions about this privacy policy, please contact us at hello@sharo.io."
          )
        ]),
        _c("h2", [_vm._v("Who we are")]),
        _c("p", [
          _vm._v(
            "We are Sharo Technologies Limited (trading as Sharo Mobility) (“Sharo”, “we”, “us”), a private limited company incorporated and registered in Ireland, with the company number 623640 with an office at 4/5 Burton Hall Park, Sandyford Industrial Estate, Dublin D18 A094."
          )
        ]),
        _c("p", [
          _vm._v(
            "A “Client Organisation” is an entity (such as your employer, college, district, community or other entity) that has purchased a subscription or license to use the Sharo App and/or Facilities on a trial or continuous basis."
          )
        ]),
        _c("p", [
          _vm._v(
            "For the purposes of the General Data Protection Regulations and any other applicable data protection and privacy laws and regulations (“ Data Protection Legislation”), Sharo Mobility will be the ‘data controller’ for all personal information we determine the means and purpose of processing. "
          )
        ]),
        _c("p", [
          _vm._v(
            "If you register an account to become a Member, Sharo will act as the data controller. You can access all of your personal information and enact all of your rights by contacting Sharo directly. Where appropriate, we will work together with your college, employer, district or community to ensure your request is carried out in compliance with Data Protection Legislation."
          )
        ]),
        _c("h2", [_vm._v("How do we use your data?")]),
        _c("p", [
          _vm._v("We gather two types of information about our Members:")
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "data that Members or their employers provide through optional, voluntary registration to the Sharo App and Facilities and the sharo.io website and"
            )
          ]),
          _c("li", [
            _vm._v(
              "data gathered through tracking information derived mainly by Sharo App usage including searches and journey bookings and schedules throughout the Sharo platform."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "This information enables us to improve the tailoring of our features and content to Members' needs and to help our Client Organisations better understand the demographics of our Members. How we use the data we collect from you is described in more detail below."
          )
        ]),
        _c("h2", [_vm._v("When you register with us")]),
        _c("p", [
          _vm._v(
            "The App offers free, voluntary registration to Members. By registering, Members can enter their journey details, search for travel options, book trips or accept/reject trip requests; and they have the ability to edit existing journey details and plans."
          )
        ]),
        _c("p", [
          _vm._v(
            "When you register with us, we will use your personal information to create your account. The details we collect from you will include your name, email address, phone number, photo, car registration number, and trip details (from/to locations, times and duration, days of the week, optional description) and where relevant your organisation name, addresses and other details. We may also collect and process data about your location when using the App."
          )
        ]),
        _c("p", [
          _vm._v(
            "If you are part of a live Smart Mobility and/or Carpooling scheme, we will also share relevant journey information with your college, employer or community to help them assess the utility of the service."
          )
        ]),
        _c("p", [
          _vm._v(
            "We need to process your personal information in this way to enter into and perform the services you have asked to receive."
          )
        ]),
        _c("h2", [
          _vm._v("When you use our services or website functionality")
        ]),
        _c("p", [
          _vm._v(
            "We use your data to provide travel options matching the journey criteria provided by you through our App."
          )
        ]),
        _c("p", [
          _vm._v(
            "We may also send out notifications or emails about requests or replies to specific trip requests. We also provide the option for you to allow other Members to respond to requests directly via the App, emails or other means such as Whatsapp or SMS."
          )
        ]),
        _c("p", [
          _vm._v(
            "Our Website also enables members to reciprocally post reviews relating to their car sharing experiences, both in a positive and negative aspect. We reserve the right to post, monitor and remove reviews relating to members as we think appropriate to deliver services to our community of members. "
          )
        ]),
        _c("p", [
          _vm._v(
            "We may also send you email notifications of specific changes to the App and our service offering. Unless we need to as part of providing the services, we never share individual email addresses, telephone numbers or travel details with any third parties, including advertisers or partners, without prior consent from the relevant Members."
          )
        ]),
        _c("p", [
          _vm._v(
            "We need to process your personal information in this way to enter into and perform the services you have asked to receive."
          )
        ]),
        _c("h2", [_vm._v("When you phone us")]),
        _c("p", [
          _vm._v(
            "When you phone us, we may also handle your personal information (your name, contact details and the other details you provide to us during the call) in order to provide the customer services you have asked us to. This could be when you ask us to confirm your account details, or explain our services to you."
          )
        ]),
        _c("h2", [
          _vm._v("When you have expressed an interest in our services:")
        ]),
        _c("p", [
          _vm._v(
            "If you have opted in via our App or website to receive marketing communications from us, we will handle your personal information (for example your name and email address) to provide you with marketing communications in line with any preferences you have told us about."
          )
        ]),
        _c("p", [
          _vm._v(
            "Every email we send to you for marketing purposes will also contain instructions on how to unsubscribe from receiving them."
          )
        ]),
        _c("p", [
          _vm._v(
            "You are not under any obligation to provide us with your personal data for marketing purposes."
          )
        ]),
        _c("p", [
          _vm._v(
            "You can tell us that you do not want your personal information to be processed in this way at any time by contacting us at hello@sharo.io or, where relevant, by following the unsubscribe link shown in every marketing communication you receive from us. "
          )
        ]),
        _c("h2", [_vm._v("To make our App better:")]),
        _c("p", [
          _vm._v(
            "We will also use your personal information to provide you with a more effective user experience (such as by displaying potential journeys or car pooling options we think you will be interested in, based on your previous travel history)."
          )
        ]),
        _c("p", [
          _vm._v(
            "Our use of your information in this way means that your experience of our App will be more tailored to you, and that the options and recommendations you see on our site may differ from someone accessing the same site with a different travel history or habits."
          )
        ]),
        _c("p", [
          _vm._v(
            "We also share your aggregated, anonymous data with third party analytics and search engine providers that assist us in the improvement and optimisation of our App and website. The following are the types of third party providers which we currently engage with:"
          )
        ]),
        _c("ul", [
          _c("li", [_vm._v("Analytics platforms")]),
          _c("li", [_vm._v("Email providers")]),
          _c("li", [_vm._v("Cloud storage companies")])
        ]),
        _c("p", [
          _vm._v(
            "We will also use your personal information for the purposes of making our App and website more secure, and to administer our site and for internal operations, including troubleshooting, data analysis, testing, research, statistical and survey purposes."
          )
        ]),
        _c("p", [
          _vm._v(
            "We process your data for this reason because we have a legitimate interest to provide you with the best experience we can, and to ensure that our site is kept secure."
          )
        ]),
        _c("p", [
          _vm._v(
            "You can prevent us from using your personal information in this way by using the 'do not track' functionality in your internet browser. If you enable do not track functionality, our website may be less tailored to your needs and preferences."
          )
        ]),
        _c("h2", [_vm._v("If our business is sold:")]),
        _c("p", [
          _vm._v("We will transfer your personal information to a third party:")
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "if we sell any business or assets, we will provide your personal information to the buyer (but only to the extent we need to, and always in accordance with data protection legislation); or"
            )
          ]),
          _c("li", [
            _vm._v(
              "if Sharo or the majority of its assets are acquired by somebody else, in which case the personal information held by Sharo will be transferred to the buyer."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "We process your personal information for this purpose because we have a legitimate interest to ensure our business can be continued by the buyer. If you object to our use of your personal information in this way, the relevant buyer of our business may not be able to provide services to you."
          )
        ]),
        _c("p", [
          _vm._v(
            "In some circumstances we may also need to share your personal information if we are under a duty to disclose or share it to comply with a legal obligation"
          )
        ]),
        _c("h2", [_vm._v("What about technical information and analytics?")]),
        _c("h3", [_vm._v("Information we collect about you")]),
        _c("p", [
          _vm._v(
            "When you visit our App or website we may automatically collect the following information:"
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "technical information, including the Internet protocol (IP) address used to connect your computer to the internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, screen resolution, operating system and platform; and"
            )
          ]),
          _c("li", [
            _vm._v(
              "information about your visit, including the full Uniform Resource Locators, clickstream to, through and from our site (including date and time), page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-overs) and methods used to browse away from the page."
            )
          ]),
          _c("li", [
            _vm._v(
              "we may collect information about the devices you use to access our services, including the hardware models, operating systems and versions, software, file names and versions, preferred languages, unique device identifiers, advertising identifiers, serial numbers, device motion information and mobile network information."
            )
          ])
        ]),
        _c("h3", [_vm._v("Information we receive from other sources")]),
        _c("p", [
          _vm._v(
            "We are also working closely with third party advertising networks, analytics providers, hosting providers and search information providers from whom we may also receive general aggregated anonymous information about you."
          )
        ]),
        _c("p", [
          _vm._v(
            "We use Hotjar in order to better understand our users’ needs and to optimize this service and experience. Hotjar is a technology service that helps us better understand our users experience (e.g. how much time they spend on which pages, which links they choose to click, what users do and don’t like, etc.) and this enables us to build and maintain our service with user feedback. Hotjar uses cookies and other technologies to collect data on our users’ behaviour and their devices. Hotjar stores this information in a pseudonymized user profile. Neither Hotjar nor we will ever use this information to identify individual users or to match it with further data on an individual user. For further details, please see Hotjar’s privacy policy by clicking on this link https://www.hotjar.com/legal/policies/privacy.  "
          )
        ]),
        _c("h2", [
          _vm._v("If you fail to provide us with Personal Information:")
        ]),
        _c("p", [
          _vm._v(
            "You always reserve the right to withhold your personal information, but this may affect how we provide our services"
          )
        ]),
        _c("p", [
          _vm._v(
            "Where we need to collect personal information by law, or under the terms of a contract we have with you and you fail to provide that data when requested, we may not be able to perform the contract we have or are trying to enter into with you. In this case, we may have to cancel a service you have with us but we will notify you if this is the case at the time."
          )
        ]),
        _c("h2", [_vm._v("Our use of Cookies")]),
        _c("h3", [_vm._v("Description of our Cookies")]),
        _c("p", [
          _vm._v(
            "Our website and Apps use cookies to provide you access to the Smart Mobility and Carpooling services and Facilities pursuant to your contract with us and pursuant to our contract with our Client Organisations. Other cookies are also used for the improvement of the App and website performance. Cookies are small text files that store information on the web browser in the memory of your device. Thus, we can obtain certain information. Cookies cannot run programs or transmit viruses to your computer. As is true of most websites and Apps, we gather certain information automatically and store it in log files. You can see the full list of the Cookies that we use for the website and for the App for what purpose these cookies are required below.  In addition, the deactivation/deletion of cookies only affects the internet browser used for this purpose."
          )
        ]),
        _c("h3", [_vm._v("Types of Cookies")]),
        _c("p", [
          _vm._v(
            "Our App and website uses cookies, which are described below to provide the carpooling services in accordance with Article 6 (1) (a) GDPR and in accordance with Article 6.1(f) GDPR, our legitimate business interest in improving user experiences for their carpooling or other journeys:"
          )
        ]),
        _c("h3", [_vm._v("Functionality of the website")]),
        _c("p", [
          _vm._v(
            "Cookies that are necessary for our website or App to function technically or that offer a service or option you have requested; e.g. a cookie that “remembers”   your personal settings, such as selected language or similar."
          )
        ]),
        _c("h3", [_vm._v("Performance Analysis of the Website")]),
        _c("p", [
          _vm._v(
            "We are constantly striving to improve our website and App. For this purpose, we use cookies that provide us with overall statistics on the number of visitors, which areas of a page are viewed most, information on the city or location of the users and the length of their stay, etc."
          )
        ]),
        _c("h3", [_vm._v("Tracking Cookies")]),
        _c("p", [
          _vm._v(
            "We use cookies to provide you with the best carpooling services and to record your journey to enable you to take advantage of the incentive programs offered by your employer."
          )
        ]),
        _c("h3", [_vm._v("Google Analytics")]),
        _c("p", [
          _vm._v(
            "We use Google Analytics in conjunction with IP anonymization. The IP address within the European Economic Area (EEA) will be abbreviated before the transmission. The IP address transmitted by Google Analytics is not merged with other Google data"
          )
        ]),
        _c("h3", [_vm._v("Deactivation/Deletion of Cookies")]),
        _c("p", [
          _vm._v(
            "To prevent the use of cookies by your internet browser or on your mobile device, you can deactivate the use of cookies in your settings (see also https://support.apple.com for iOS devices, and https://support.google.com for Android devices). Furthermore, cookies that have already been set can be deleted at any time via an internet browser or other software programs in your App. You can use the help functions of your internet browser to learn how to deactivate and/or delete cookies. If you deactivate or delete the cookies, it may result in certain functions of our App or website no longer functioning and you may no longer be able to use a service and/or the Facilities. For other internet browsers, the deactivation/deletion of cookies must be repeated accordingly. If you de-install the App, no more information will be collected."
          )
        ]),
        _c("h3", [_vm._v("Duration of the Cookies")]),
        _c("p", [
          _vm._v(
            "We store and process the data until the end of the term of a respective cookie or until you delete the cookies or withdraw your consent."
          )
        ]),
        _c("h2", [_vm._v("Where is my data stored?")]),
        _c("p", [
          _vm._v(
            "The data that we collect from you will be stored in a location within the EU."
          )
        ]),
        _c("p", [
          _vm._v(
            "All information you provide to us is stored securely on Google Cloud Platform databases. Where you have chosen (or we have given you) a password which enables you to access certain parts of our App or website, you are responsible for keeping this password confidential. You must not share your password with anyone."
          )
        ]),
        _c("h2", [_vm._v("How long do we retain your data for?")]),
        _c("p", [
          _vm._v(
            "We will only retain your information for the period necessary to fulfil the purposes outlined in this Privacy Policy and as necessary to comply with our legal obligations, resolve disputes, and enforce our agreements. We delete your Personal Data that is no longer necessary, when you withdraw your consent or cancel your account. If the data are not deleted because they are necessary for other and legally permissible purposes, their processing is restricted. This means that the data is blocked and not processed for other purposes."
          )
        ]),
        _c("p", [
          _vm._v(
            "Remember that after you cancel your account, copies of some information from your account may remain viewable in some circumstances where, for example, you have shared information with social media or other services. We will use our best endeavours to contact any third parties to request the deletion of your data, if you request this."
          )
        ]),
        _c("p", [
          _vm._v(
            "used your personal information to contact you for marketing communications:"
          )
        ]),
        _c("p", [
          _vm._v(
            "If you tell us that you no longer wish to receive marketing communications from us, we will stop sending them to you. If you wish to unsubscribe from our marketing communications, we will immediately remove your personal information from our marketing database and ensure your contact details are suppressed to prevent us contacting you in future about promotional offerings unless you inform us otherwise. "
          )
        ]),
        _c("p", [
          _vm._v(
            "Any restorations of data for development or testing purposes is anonymised."
          )
        ]),
        _c("h2", [_vm._v("Lawful basis of processing")]),
        _c("p", [
          _vm._v(
            "We will only process your Personal Data and information for as long as we have a relevant legal basis to do so. This is usually in order to provide you with the services you have requested from Sharo and unless stated, all of the above processing is based on fulfillment of our pre-contractual and contractual obligations to you and our Client Organisations in accordance with Article 6 (1)(b), your consent in accordance with Article 6 (1) (a) of the GDPR and in accordance with our T&Cs. "
          )
        ]),
        _c("h2", [_vm._v("Your right to object under data protection laws")]),
        _c("p", [
          _vm._v(
            "You have the right to object to us handling your personal information when:"
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "we are handling your personal information based on our legitimate interests (as described in the “How do you use my data” section above). If you ask us to stop handling your personal information in this way, we will stop unless we can show you that we have compelling grounds as to why our use of your personal information should continue"
            )
          ]),
          _c("li", [
            _vm._v(
              "for marketing purposes. If you ask us to stop handling your personal information on this basis, we will stop"
            )
          ])
        ]),
        _c("h2", [_vm._v("What are my rights under data protection laws?")]),
        _c("p", [
          _vm._v(
            "You have various rights under the data protection laws, which you can exercise by contacting us at hello@sharo.io."
          )
        ]),
        _c("h3", [_vm._v("Right of access")]),
        _c("p", [
          _vm._v(
            "You are entitled to receive confirmation as to whether your personal information is being processed by us, as well as various other information relating to our use of your personal information."
          )
        ]),
        _c("p", [
          _vm._v(
            "You also have the right to access your personal information which we are handling."
          )
        ]),
        _c("p", [
          _vm._v(
            "Any access request may be subject to a fee (subject to statutory limits) to meet our costs in providing you with details of the information we hold about you."
          )
        ]),
        _c("h3", [_vm._v("Right of rectification")]),
        _c("p", [
          _vm._v(
            "You have the right to correct inaccurate or incomplete personal data, including by means of providing a supplementary statement."
          )
        ]),
        _c("h3", [_vm._v("Right to restriction of processing")]),
        _c("p", [
          _vm._v(
            "You can also ask us to use your data only to a limited extent. This means that your data may still be stored but may only be used for limited purposes (e.g. to assert legal claims)."
          )
        ]),
        _c("h3", [_vm._v("Right to data portability")]),
        _c("p", [
          _vm._v(
            "You have the right to receive the personal information we hold about you in a structured, standard machine readable format and to send this to another organisation controlling your personal information."
          )
        ]),
        _c("h3", [_vm._v("Right to be forgotten (erasure)")]),
        _c("p", [
          _vm._v(
            "You have the right to require us to erase your personal information which we are handling in the following circumstances:"
          )
        ]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "we no longer need to use your personal information for the reasons we told you we collected it for"
            )
          ]),
          _c("li", [
            _vm._v(
              "where we needed your consent to use your personal information and you have withdrawn your consent"
            )
          ]),
          _c("li", [
            _vm._v(
              "you object to our use of your personal information and we have no compelling reason to carry on handling your personal information"
            )
          ]),
          _c("li", [
            _vm._v(
              "our handling of your personal information has broken the law"
            )
          ]),
          _c("li", [
            _vm._v(
              "we must erase your personal information to comply with a law we are subject to"
            )
          ])
        ]),
        _c("h3", [_vm._v("Right to withdraw consent")]),
        _c("p", [
          _vm._v(
            "You may at any time object and withdraw your consent to our processing of your personal data by contacting us at hello@sharo.io. Be aware that should you withdraw consent, you may no longer be able to use and access certain information or features of the App, website or the Facilities."
          )
        ]),
        _c("p", [
          _vm._v(
            "To exercise one or more of these rights, or to ask a question about these rights or any other provision of this policy, or about our processing of your personal data, please use the contact details provided below."
          )
        ]),
        _c("p", [_vm._v("Please note that:")]),
        _c("ul", [
          _c("li", [
            _vm._v(
              "we may require proof of your identity before we can give effect to these rights"
            )
          ]),
          _c("li", [
            _vm._v(
              "where your request requires the establishment of additional facts, e.g., a determination of whether any Processing is non-compliant with applicable law, we will investigate your request reasonably promptly, before deciding on what action to take."
            )
          ])
        ]),
        _c("p", [
          _vm._v(
            "Be advised that you have the right to apply directly to data protection authorities in your country for advice or in order to lodge a complaint if you have concerns about how we process your personal data."
          )
        ]),
        _c("h2", [_vm._v("What about websites we link to?")]),
        _c("p", [
          _vm._v(
            "Our App or website may, from time to time, contain links to and from the websites of our partner networks and affiliates."
          )
        ]),
        _c("p", [
          _vm._v(
            "If you follow a link to any of these websites or use our services, please note that you have left our site and these websites have their own privacy policies."
          )
        ]),
        _c("p", [
          _vm._v(
            "We do not accept any responsibility or liability for these policies or websites. Please check their policies before you submit any personal information to these websites."
          )
        ]),
        _c("h2", [_vm._v("Information regarding children")]),
        _c("p", [
          _vm._v(
            "We do not intentionally market our services or collect information via our App or website from data subjects under the age of 18. We do not collect information regarding children for the provision of our services and will erase any data collected if informed by the parent or legal guardian of a child whose data we have erroneously collected."
          )
        ]),
        _c("h2", [_vm._v("When will you change your privacy policy?")]),
        _c("p", [
          _vm._v(
            "Any changes we make to our privacy policy in the future will be posted on this page and, where appropriate, notified to you by e-mail."
          )
        ]),
        _c("p", [
          _vm._v(
            "Please check back frequently to see any updates or changes to our privacy policy."
          )
        ]),
        _c("h2", [_vm._v("How do I contact you with feedback?")]),
        _c("p", [
          _vm._v(
            "Questions, comments and requests regarding this privacy policy or your statutory rights are welcomed and should be addressed to hello@sharo.io or via standard mail to Sharo Mobility,4/5 Burton Hall Road, Sandyford, Dublin 18."
          )
        ]),
        _c("p", [
          _vm._v("This privacy policy was last updated in January 2020. ")
        ])
      ]
    )
  }
]
render._withStripped = true

export { render, staticRenderFns }